

























import { Component, Vue } from "vue-property-decorator";
import { Tile } from "@/types";
import loader from "@/configLoader";

@Component
export default class Home extends Vue {

  private tiles: Array<Tile> = [
    {
      title: "IMS - AdminTool",
      desc: this.$vuetify.lang.t("$vuetify.desc.ims"),
      link: this.tileLink("ims", "/loginmaster/adminTool"),
      id: "adminTool"
    },
    {
      title: "Keycloak administration",
      desc: this.$vuetify.lang.t("$vuetify.desc.keycloak"),
      link: this.tileLink("keycloak", "/auth"),
      id: "keycloak"
    },
    {
      title: "Registration",
      desc: this.$vuetify.lang.t("$vuetify.desc.registration"),
      link: this.tileLink("registration", ""),
      id: "registration"
    },
    {
      title: "MyProfile",
      desc: this.$vuetify.lang.t("$vuetify.desc.profile"),
      link: this.tileLink("profile", ""),
      id: "myProfile"
    },
    {
      title: "SecuRole® Demo",
      desc: this.$vuetify.lang.t("$vuetify.desc.securole-demo"),
      link: this.tileLink("securole-demo", ""),
      id: "securoleDemo"
    },
    {
      title: "Mails",
      desc: this.$vuetify.lang.t("$vuetify.desc.mails"),
      link: this.tileLink("mails", ""),
      id: "mailDev"
    },
    {
      title: "Portainer",
      desc: this.$vuetify.lang.t("$vuetify.desc.portainer"),
      link: this.tileLink("portainer", ""),
      id: "portainer"
    },
    {
      title: "Prometheus",
      desc: this.$vuetify.lang.t("$vuetify.desc.prometheus"),
      link: this.tileLink("prometheus", ""),
      id: "prometheus"
    },
    {
      title: "Grafana",
      desc: this.$vuetify.lang.t("$vuetify.desc.grafana"),
      link: this.tileLink("grafana", ""),
      id: "grafana"
    },
    {
      title: "Rabbit-MQ",
      desc: this.$vuetify.lang.t("$vuetify.desc.rmq"),
      link: this.tileLink("rabbitmq", ""),
      id: "rmq"
    }
  ];

  tileLink(prefix: string, suffix: string): string {
    const host = loader.getConfigValue("VUE_APP_HOST_DOMAIN", "localhost");
    const deploymentMode = loader.getConfigValue("VUE_APP_DEPLOYMENT_MODE", "docker");
    const protocol = loader.getConfigValue("VUE_APP_PROTOCOL", "https");
    const protocolHostConjunction = "://";
    if(deploymentMode == 'kubernetes') {
      return protocol + protocolHostConjunction + prefix + "." + host + suffix;
    }
    else if(prefix == 'ims' || prefix == 'keycloak') { // except for ims and keycloak has both prefix and suffix for docker deployment mode
      return protocol + protocolHostConjunction + host + suffix;
    } else {
      return protocol + protocolHostConjunction + host + prefix;
    }
  }

  openLink(link: string): void {
    window.location.assign(link)
  }

  get activeTiles(): Array<Tile> {
    const allTiles = "*";
    const ids = loader
      .getConfigValue("VUE_APP_ACTIVE_TILES", allTiles)
      .split(",");
    return this.tiles.filter(
(      tile: { id: any; }) => ids.indexOf(allTiles) == 0 || ids.indexOf(tile.id) >= 0
    );
  }
}
